.Scrolltop {
    position: fixed;
    right: 34px;
    bottom: 34px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    background-color: var(--bg-blinking-color-3);
    color: #fff;
    font-weight: 900;
    z-index: 1;
    text-align: center;
    padding-top: 3px;
    line-height: 34px;
    border-radius: 3px;
    -webkit-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    cursor: pointer;
}