	/*
  	Flaticon icon font: Flaticon
  	Creation date: 18/04/2019 05:37
  	*/

	@font-face {
		font-family: "Flaticon";
		src: url("../fonts/Flaticon.eot");
		src: url("../fonts/Flaticond41d.eot?#iefix") format("embedded-opentype"),
			url("../fonts/Flaticon.woff2") format("woff2"),
			url("../fonts/Flaticon.woff") format("woff"),
			url("../fonts/Flaticon.ttf") format("truetype"),
			url("../fonts/Flaticon.svg#Flaticon") format("svg");
		font-weight: normal;
		font-style: normal;
	}

	@media screen and (-webkit-min-device-pixel-ratio:0) {
		@font-face {
			font-family: "Flaticon";
			src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
		}
	}

	[class^="flaticon-"]:before,
	[class*=" flaticon-"]:before,
	[class^="flaticon-"]:after,
	[class*=" flaticon-"]:after {
		font-family: Flaticon;
		font-size: 20px;
		font-style: normal;
		margin-left: 0px;
	}

	.flaticon-add:before {
		content: "\f100";
	}

	.flaticon-percentage:before {
		content: "\f101";
	}

	.flaticon-next:before {
		content: "\f102";
	}

	.flaticon-right-upper-coner:before {
		content: "\f103";
	}

	.flaticon-zoom:before {
		content: "\f104";
	}

	.flaticon-back:before {
		content: "\f105";
	}

	.flaticon-arrow:before {
		content: "\f106";
	}

	.flaticon-add-1:before {
		content: "\f107";
	}

	.flaticon-substract:before {
		content: "\f108";
	}

	.flaticon-leaflet:before {
		content: "\f109";
	}

	.flaticon-play-button:before {
		content: "\f10a";
	}

	.flaticon-car:before {
		content: "\f10b";
	}

	.flaticon-architecture-and-city:before {
		content: "\f10c";
	}

	.flaticon-question:before {
		content: "\f10d";
	}

	.flaticon-remove:before {
		content: "\f106";
	}

	.flaticon-right-quote-1::before {
		content: "\f13d";
	}