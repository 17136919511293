/* //// Home Faqs css ///// */


.HomeFaqs {
    padding: 80px 0px;
    position: relative;
}

.HomeFaqs h2 {
    line-height: 52px;
}

.HomeFaqs p {
    margin-bottom: 20px;

}

.HomeFaqs-para {
    margin-bottom: 80px;
}


.HomeFaqs img {
    margin: 30px auto;

}

.HomeFaqs .btn_one {
    color: var(--text-color-1);
    margin: 10px 0px;
}

.accordion-body h1 {
    margin-bottom: 20px;
}

/* Responsive.css */


@media (max-width: 374.98px) {


    .HomeFaqs-box-1 {
        margin-left: 10px !important;
    }


}

@media (min-width: 375.98px) and (max-width: 575.98px) {

    .HomeFaqs-box-1 {
        margin-left: 10px !important;
    }



}


@media (min-width: 576px) and (max-width: 767.98px) {

    .HomeFaqs-box-1 {
        margin-left: 10px !important;
    }



}


@media (min-width: 768px) and (max-width: 991.98px) {

    .HomeFaqs-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}