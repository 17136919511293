.subscribe .subscribe-form input[type="email"] {
    position: relative;
    display: block;
    background: #27282c;
    border: 1px solid #404043;
    color: var(--text-color-2);
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    height: 52px;
    margin-bottom: 0px;
    padding: 0 20px;
    transition: all 500ms ease;
}

.subscribe .subscribe-form button {
    margin-top: 20px;
    line-height: 50px;
    width: 100%;
}

.subscribe .subscribe-box .text p {
    color: var(--text-color-2);
    font-size: 15px;
    margin-top: 15px;
}

.subscribe .our-info-box .text p {
    color: var(--text-color-2);
    margin: 0;
}

.subscribe .our-info-box .text {
    position: relative;
    display: block;
    padding-bottom: 23px;
}

.subscribe-box span {
    /* margin-right: 35px;*/

}

.subscribe .subscribe-box .text p span {
    color: var(--text-color-5);
}