/* //// Home Testimonial css ///// */


.HomeTestimonial {
    padding: 80px 0px;
    position: relative;
}

.HomeTestimonial h2 {
    line-height: 52px;
}


.HomeTestimonial img {
    margin: 30px auto;

}

.HomeTestimonial p {
    margin: 30px auto;

}

.HomeTestimonial-box-1 {
    position: relative;
}

.HomeTestimonial-box-1 .HomeTestimonial-inner-box {
    position: relative;
    margin-bottom: 30px;
    background-color: #f8f8f8;
    padding: 45px 30px;
    padding-left: 130px;
}

.HomeTestimonial-box-1 .image {
    position: absolute;
    right: 40px;
    bottom: 18px;
}

.HomeTestimonial-box-1 .image .image-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.HomeTestimonial-box-1 .image .image-wrapper:before {
    position: absolute;
    content: '';
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: 50%;
}

.HomeTestimonial-box-1 .image img {
    border-radius: 50%;
}

.HomeTestimonial-box-1 h4 {
    position: relative;
    font-size: 24px;
    font-weight: 600;
}

.HomeTestimonial-box-1 .designation {
    position: relative;
}

.HomeTestimonial-box-1 .border-shape {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
}

.HomeTestimonial-box-1 .border-shape:before {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    width: 1px;
    height: 200px;
    transform: translateY(-50%) translateX(-50%);
}

.HomeTestimonial-box-1 .border-shape:after {
    position: absolute;
    content: '';
    left: 50%;
    top: -10px;
    width: 3px;
    height: 80px;
    background: #f8f8f8;
    transform: translateX(-50%);
}

.HomeTestimonial-box-1 .quote-icon {
    position: relative;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 22px;
    color: #ffffff;
    z-index: 2;
    text-align: center;
    transition: .5s;
}

.HomeTestimonial-box-1 .HomeTestimonial-inner-box:hover .quote-icon {
    color: #212121;
}

.theme-color-two .HomeTestimonial-box-1 .HomeTestimonial-inner-box:hover .quote-icon {
    color: #fff;
}

.HomeTestimonial-box-1 .quote-icon:before {
    position: absolute;
    content: '';
    left: 1px;
    top: 1px;
    bottom: 1px;
    right: 1px;
    border-radius: 50%;
    border: 4px solid #f8f8f8;
    transition: .5s;
}

.HomeTestimonial-box-1 .HomeTestimonial-inner-box:hover .quote-icon:before {
    opacity: 0;
}

.HomeTestimonial-box-1 .quote-icon:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    transition: .5s;
    transform: scale(.8);
    opacity: 0;
}

.HomeTestimonial-box-1 .HomeTestimonial-inner-box:hover .quote-icon:after {
    transform: scale(1);
    opacity: 1;
}

.HomeTestimonial-box-1 .text {
    position: relative;
    margin-bottom: 22px;
}

.HomeTestimonial-box-1 .rating {
    position: relative;
    font-size: 13px;
    margin-bottom: 20px;
}

.HomeTestimonial-box-1 .rating span {
    margin: 0 2px;
}

.HomeTestimonial-box-1 .border-shape::before {
    background: #fb5a0f;
}

.HomeTestimonial-box-1 .quote-icon {
    background-color: #fb5a0f;
}

/* Responsive.css */

@media (max-width: 374.98px) {


    .HomeTestimonial-box-1 {
        margin-left: 10px !important;
    }



}

@media (min-width: 375.98px) and (max-width: 575.98px) {


    .HomeTestimonial-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 576px) and (max-width: 767.98px) {

    .HomeTestimonial-box-1 {
        margin-left: 10px !important;
    }


}


@media (min-width: 768px) and (max-width: 991.98px) {


    .HomeTestimonial-box-1 {
        margin-left: 10px !important;
    }


}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}