.mr-0 {
    margin: 0px;
}

.mr-10 {
    margin: 10px;
}

.mr-top-25 {
    margin-top: 25px;
}

.mr-bottom-25 {
    margin-bottom: 25px;
}

.mr-bottom-50 {
    margin-bottom: 50px;
}


/* Responsive.css */

@media (max-width: 374.98px) {
    .padding-lr p {
        padding: 0px 0px;
    }
}

@media (min-width: 375.98px) and (max-width: 575.98px) {
    .padding-lr p {
        padding: 0px 0px;
    }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .padding-lr p {
        padding: 0px 0px;
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .padding-lr p {
        padding: 0px 0px;
    }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .padding-lr p {
        padding: 0px 0px;
    }
}


@media (min-width: 1200px) and (max-width: 1399.98px) {
    .padding-lr p {
        padding: 0px 90px;
    }
}