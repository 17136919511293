.comment {
    margin: 0px 0px 0px 0px;
}

.comment h3 {
    margin: 5px 0px;
}

.commentdetails h4 {
    margin: 70px 0px 30px 0px;
}

.commentdetails ul {
    margin: 0px;
}

.commentdetails ul li {
    margin: 10px 0px;
}

.commentdetails .fa-check {
    margin-right: 10px;
    color: #205f7f;
}

.comment {
    background-color: #f7f9fe;
    padding: 5px;
}

.comment .form-control {
    padding: 17px 24px;
    height: auto;
    border-radius: 0;
    border: 1px solid transparent;
    font-size: 14px;
    background-color: transparent;
    margin: 15px 0px;
}

.comment .form-group {
    margin-bottom: 1rem;
}

.comment .submit-btn {
    color: #fff;
    border-color: #2d4a8a;
    font-size: 16px;
    padding: 15px 30px;
    display: inline-block;
    line-height: normal;
    border: 1px solid transparent;
    position: relative;
    font-weight: 600;
    overflow: hidden;
    z-index: 2;
    vertical-align: middle;
    text-transform: capitalize;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    background-color: #2d4a8a;
    width: 100%;
    margin: 40px 0px;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    /* padding: 1rem; */
}