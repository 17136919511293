/* //// Home Blog css ///// */

.HomeBlog {
    padding: 80px 0px;
    background-color: var(--bg-blinking-color-6);
}

.HomeBlog h2 {
    line-height: 52px;
}


.HomeBlog img {
    margin: 30px 0px;

}

.HomeBlog p {
    margin-bottom: 30px;
}

.HomeBlog-box-1 {
    background-color: #fff;
    margin-bottom: 10px;
    -webkit-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
    box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
}

.HomeBlog-imagebox-blog {
    background-color: #fff;
    margin-bottom: 10px;
    -webkit-box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
    box-shadow: 0 0 12px 0 rgba(32, 46, 60, 0.06);
    margin: 0px 0px 0px 20px;
}

.HomeBlog-imagebox-blog .HomeBlog-thumbnail {
    position: relative;
    overflow: hidden;
}

.HomeBlog-title h5 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 25px;
}

.HomeBlog-imagebox-blog .HomeBlog-thumbnail img {
    -webkit-transform: scale(1.075);
    transform: scale(1.075);
    -webkit-transition: all 3s ease-out;
    transition: all 3s ease-out;
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
    margin: 0px;
}

.HomeBlog-imagebox-blog .HomeBlog-content {
    padding: 15px 25px 25px 25px;
    position: relative;
}

.HomeBlog-imagebox-blog .HomeBlog-content a:hover {
    color: #2d4a8a;
}

.HomeBlog-imagebox-blog .post-meta {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    margin: 25px 0px;
}

.HomeBlog-imagebox-blog .post-meta i {
    padding-right: 5px;
}

.HomeBlog-imagebox-blog .post-meta .ttm-meta-line {
    position: relative;
}

.HomeBlog-imagebox-blog .post-meta .ttm-meta-line:not(:last-child) {
    padding-right: 23px;
}

.HomeBlog-imagebox-blog .post-meta .ttm-meta-line:not(:last-child)::after {
    position: absolute;
    content: "";
    height: 16px;
    width: 1px;
    right: 10px;
    top: 0;
    background-color: #dce1e9;
}

.HomeBlog-imagebox-blog .post-meta .ttm-meta-line i,
.HomeBlog-imagebox-blog .post-meta .ttm-meta-line svg {
    color: #2d4a8a;
    margin-right: 5px;
}

.HomeBlog-imagebox-blog .ttm-box-post-date {
    left: 25px;
    top: -35px;
    border-radius: unset;
}

.HomeBlog-imagebox-blog:hover {
    -webkit-box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

.HomeBlog-imagebox-blog:hover .ttm-blog-overlay-iconbox a {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.HomeBlog-imagebox-blog:hover .ttm-box-view-overlay::before,
.HomeBlog-imagebox-blog:hover .ttm-box-view-overlay::after {
    opacity: .6;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    background-color: #263045;
}

.HomeBlog-imagebox-blog.style2 {
    padding: 15px;
}

.HomeBlog-imagebox-blog.style2 .HomeBlog-content {
    padding: 25px 5px 5px;
}

.HomeBlog-imagebox-blog.style2 .ttm-box-post-date {
    left: 15px;
    top: -80px;
}

.HomeBlog-imagebox-blog.style2 .ttm-box-post-date .entry-date {
    display: block;
    line-height: 30px;
}

.HomeBlog-imagebox-blog.style2 .ttm-entry-date span {
    display: block;
    background-color: #fff;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.ttm-blog-overlay-iconbox a {
    opacity: 0;
    position: absolute;
    text-align: center;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -17px;
    display: inline-block;
    height: 25px;
    width: 25px;
    line-height: 25px;
    color: #fff;
    font-size: 25px;
    background: transparent;
    -webkit-transition: all .9s ease;
    transition: all .9s ease;
    -webkit-transform: scale(0);
    transform: scale(0);
}


.ttm-box-post-date {
    position: absolute;
    left: 15px;
    top: 15px;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 17px;
    color: #fff;
}

.ttm-box-post-date .entry-date {
    display: block;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: center;
    align-self: center;
    background-color: #2d4a8a;
    padding: 10px;
}

.ttm-entry-date span {
    display: block;
    font-weight: bold;
}

.HomeBlog-imagebox-blog .ttm-box-view-overlay::after,
.HomeBlog-imagebox-blog .ttm-box-view-overlay::before {
    content: '';
    position: absolute;
    left: 0 !important;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}

.HomeBlog-imagebox-blog .ttm-box-view-overlay::before {
    right: 0 !important;
    left: auto;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
}

/* Responsive.css */

@media (max-width: 374.98px) {

    .HomeBlog-box-1 {
        margin-left: 10px !important;
    }



}

@media (min-width: 375.98px) and (max-width: 575.98px) {

    .HomeBlog-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 576px) and (max-width: 767.98px) {

    .HomeBlog-box-1 {
        margin-left: 10px !important;
    }



}


@media (min-width: 768px) and (max-width: 991.98px) {

    .HomeBlog-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}