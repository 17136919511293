.page-title {
    position: relative;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.breadcrumb ol {
    margin: 0px;
    padding: 0px;
}

.page-title .content-box {
    position: relative;
    padding: 67px 0px;
}

.page-title .content-box h1 {
    position: relative;
    display: block;
    font-size: 46px;
    line-height: 55px;
    color: #fff;
    font-weight: 600;

}

.page-title .content-box p {

    font-size: 16px;
    line-height: 55px;
    color: #fff;

}

.page-title .content-box .bread-crumb {
    position: absolute;
    bottom: 20px;
    right: 0;
}

.page-title .content-box .bread-crumb li {
    color: #f7c212;
}

.page-title .content-box .bread-crumb li {
    position: relative;
    display: inline-block;
    font-size: 14px;
    padding-right: 20px;
    margin-right: 10px;
    font-weight: 500;
    text-transform: uppercase;
}

.page-title .content-box .bread-crumb li:last-child {
    padding: 0px;
    margin: 0px;
}

.page-title .content-box .bread-crumb li a {
    color: #fff;
}

.page-title .content-box .bread-crumb li::after {
    position: absolute;
    content: "\f101";
    font-family: 'Font Awesome 5 Pro';
    top: 0;
    right: -3px;
    font-size: 11px;
    font-weight: normal;
    color: #fff;
}

.page-title .content-box .bread-crumb li:last-child::after {
    display: none;
}