.HomeTeam {
    padding: 80px 0px;
    position: relative;
}

.HomeTeam p {
    margin-bottom: 30px;
}

.HomeTeam h2 {
    line-height: 52px;
    margin-bottom:20px
}


.HomeTeam img {
    margin: 15px auto;

}



.HomeTeam-box-1 .HomeTeam-inner-box {
    margin-bottom: 30px;
    position: relative;
    z-index: 9;
}

.HomeTeam-box-1 .image {
    position: relative;
    overflow: hidden;
}

.HomeTeam-box-1 .image img {
    position: relative;
    transition: .5s;
    width: 100%;
}

.HomeTeam-box-1 .HomeTeam-inner-box:hover .image img {
    transform: scale(1.05);
}

.HomeTeam-box-1 .content {
    position: relative;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    padding: 22.5px 30px;
    background: #fff;
    text-align: center;
}
.HomeTeam-box-1 .designation {
	position: absolute;
	bottom: 13px;
	left: 0%;
	font-size: 20px;
	font-weight: 400;
	color: #4b4b4b;
	text-transform: capitalize;
	background: #fff;
	padding: 11.5px 4px;
	transition: .5s;
	/* transform: translateX(-50%); */
	/* width: 170px; */
	text-align: center;
	width: 100%;
    background-color: var(--bg-blinking-color-1);
}


.HomeTeam-box-1 .HomeTeam-inner-box:hover .designation {
    opacity: 0;
}

.HomeTeam-box-1 h4 {
    position: relative;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
}

.HomeTeam-box-1 .social-icon {
    position: relative;
}

.HomeTeam-box-1 .social-icon li {
    display: inline-block;
    margin: 0 3px;
}

.HomeTeam-box-1 .social-icon a {
    position: relative;
    display: inline-block;
    color: #212121;
    font-size: 16px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: #f8f8f8;
    border-radius: 50%;
    transition: .5s;
}

.HomeTeam-box-1 .social-icon a:hover {
    color: #fff;
}

.HomeTeam-box-1 .overlay {
    position: absolute;
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 30px;
    background: #f4a636;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: scaleY(.5);
    transform-origin: bottom;
    transition: .7s;
    z-index: 9;
    padding: 30px;
    text-align: center;
}

.HomeTeam-box-1 .HomeTeam-inner-box:hover .overlay {
    opacity: 1;
    transform: scaleY(1);
}


/* Responsive.css */

@media (max-width: 374.98px) {


    .HomeTeam-box-1 {
        margin-left: 10px !important;
    }



}

@media (min-width: 375.98px) and (max-width: 575.98px) {


    .HomeTeam-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 576px) and (max-width: 767.98px) {

    .HomeTeam-box-1 {
        margin-left: 10px !important;
    }



}


@media (min-width: 768px) and (max-width: 991.98px) {

    .HomeTeam-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}