/* //// Success Profile css ///// */


.SuccessProfile {
    padding: 80px 0px;
    background-color: var(--bg-blinking-color-7);
}

.SuccessProfile h1 {
    line-height: 52px;
    color: var(--text-color-1);
}


.SuccessProfile img {
    margin: 30px auto;

}

.SuccessProfile .btn_one {
    color: var(--text-color-1);
    margin: 10px 0px;
}

.SuccessProfile-box-1 {

    background-color: var(--bg-transparent);
    /* max-width: 170px; */
    /*  padding: 40px 20px;*/
    border-radius: 10px;
    position: relative;
    box-shadow: -2px 0px 28px 0px rgb(0 0 0 / 5%);
    /* border: 1px solid rgb(231, 231, 231);*/
    margin-top: 30px;
    transition: all 0.3s linear;
}

.SuccessProfile-box-1 h5 {
    font-size: 72px;
    font-weight: bold;
    margin: 40px 0px;
    color: var(--text-color-1);
}

.SuccessProfile-box-1 h6 {
    font-size: 16px;
    font-weight: bold;
    color: var(--text-color-1);

}



.SuccessProfile-inner-box span {
    font-size: 42px;
    color: var(--text-color-1);

}

/* Responsive.css */

@media (max-width: 374.98px) {


    .SuccessProfile-box-1 {
        margin-left: 10px !important;
    }



}

@media (min-width: 375.98px) and (max-width: 575.98px) {


    .SuccessProfile-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 576px) and (max-width: 767.98px) {

    .SuccessProfile-box-1 {
        margin-left: 10px !important;
    }



}


@media (min-width: 768px) and (max-width: 991.98px) {

    .SuccessProfile-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}