@media only screen and (max-width: 1199px) {

    .menu-side-nav .logo {
        padding: 10px 10px 20px 10px;
    }

    .mobile-menu .close-btn {
        /*  width: 100%;*/
        line-height: 30px;
        color: #ffffff;
        cursor: pointer;
        z-index: 10;
        -webkit-transition: all 0.9s ease;
        -o-transition: all 0.9s ease;
        transition: all 0.9s ease;
        /* float: right;*/
        padding: 0px 25px 0px 0px;
    }

    .mobile-menu .close-btn .flaticon-remove::before {
        content: "\f106";
        font-size: 32px;
    }


    .menubar-toggler {
        position: relative;
        color: rgb(255, 255, 255);
        cursor: pointer;
        float: right;
        margin: 25px 0;
        display: block;
        text-align: right;
    }

    .menu-side-nav-open {
        position: fixed;
        right: 0px;
        top: 0px;
        max-width: 250px;
        height: 100%;
        max-height: 100%;
        overflow-x: hidden;
        background: #202020;
        padding: 20px 0px;
        z-index: 5;
        border-radius: 0px;
        opacity: 1;
        visibility: visible;
        -webkit-transition: all 0.7s ease;
        -o-transition: all 0.7s ease;
        transition: all 0.7s ease;
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
    }

    .menu-side-nav-close {
        position: fixed;
        right: 0px;
        top: 0px;
        max-width: 250px;
        height: 100%;
        max-height: 100%;
        overflow-x: hidden;
        background: #202020;
        padding: 20px 0px;
        z-index: 5;
        opacity: 0;
        visibility: hidden;
        border-radius: 0px;
        -webkit-transition: all 0.7s ease;
        -o-transition: all 0.7s ease;
        transition: all 0.7s ease;
        -webkit-transform: translateX(101%);
        -ms-transform: translateX(101%);
        transform: translateX(101%);


    }

    .menu-overlay-close {
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        -webkit-transform: translateX(101%);
        -ms-transform: translateX(101%);
        transform: translateX(101%);
        transition: all 900ms ease;
        -moz-transition: all 900ms ease;
        -webkit-transition: all 900ms ease;
        -ms-transition: all 900ms ease;
        -o-transition: all 900ms ease;
        background-color: #000;
    }

    .menu-overlay-open {
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 1;
        visibility: visible;
        -webkit-transition: all 0.7s ease;
        -o-transition: all 0.7s ease;
        transition: all 0.7s ease;
        -webkit-transform: translateX(0%);
        -ms-transform: translateX(0%);
        transform: translateX(0%);
        background-color: rgba(0, 0, 0, 0.5);

    }

    .mobile-menu .navigation:last-child {
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    }

    .mobile-menu .navigation li {
        position: relative;
        display: block;
        border-top: 1px solid rgba(255, 255, 255, 0.10);
    }

    .mobile-menu .navigation li:first-child {
        border-top: 0px;
    }

    .mobile-menu .navigation li>a {
        position: relative;
        display: block;
        line-height: 24px;
        padding: 10px 25px;
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        text-transform: uppercase;
        -webkit-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
    }

    .mobile-menu .navigation li>a::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 0;
        -webkit-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
    }

    .mobile-menu .navigation li>a {
        line-height: 24px;
        font-size: 15px;
        font-weight: 500;
        color: #ffffff;
        text-transform: uppercase;
    }

    .mobile-menu .navigation li.dropdown .dropdown-btn {
        position: absolute;
        right: 12px;
        top: 6px;
        width: 32px;
        height: 32px;
        text-align: center;
        font-size: 16px;
        line-height: 32px;
        color: #ffffff;
        background: rgba(255, 255, 255, 0.10);
        cursor: pointer;
        border-radius: 2px;
        -webkit-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
        z-index: 5;
    }



    .active::after,
    hover {
        color: #ffffff;
        content: "\f104";
        font-family: 'Font Awesome 5 Pro';
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }


    .mobile-menu .navigation li.dropdown .dropdown-btn::after {
        color: #ffffff;
        content: "\f105";
        font-family: 'Font Awesome 5 Pro';
    }


    .mobile-menu .social-links {
        position: relative;
        text-align: center;
        padding: 30px 25px;
    }

    .mobile-menu .social-links li {
        position: relative;
        display: inline-block;
        margin: 0px 10px 10px;
    }

    .mobile-menu .social-links li a {
        position: relative;
        line-height: 32px;
        font-size: 16px;
        color: #ffffff;
        -webkit-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
    }

    .mobile-menu .navigation li>a {
        margin-right: 15px;
    }

    .drop-down-open {
        display: block;
    }

    .drop-down-close {
        display: none;
    }

    .subdrop-down-open {
        display: block;
    }

    .subdrop-down-close {
        display: none;
    }

}